import '@aws-amplify/ui-react/styles.css';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { useRouter } from 'next/router';

interface Props {
  children: JSX.Element;
}

export const NonRequireAuth = ({ children }: Props) => {
  const router = useRouter();
  const { route } = useAuthenticator(context => [context.route]);

  if (route === 'authenticated') {
    // ログイン中なら注文管理画面を表示
    router.push('/orders');
    return null;
  }

  return children;
};
