import { Authenticator, Heading } from '@aws-amplify/ui-react';
import { ListItem, Text, UnorderedList } from '@chakra-ui/react';
import Image from 'next/image';

import { AuthenticatorFormFields } from '@/utils/authenticator-form-fields';

const PasswordDescription = () => (
  <>
    <Text className="text-sm">
      パスワードを入力してください。新しいパスワードに必要な条件は以下の通りです。
    </Text>
    <UnorderedList className="text-sm" marginInlineStart={4}>
      <ListItem>8文字以上</ListItem>
      <ListItem>1つ以上の数字、記号、大文字、小文字</ListItem>
    </UnorderedList>
  </>
);

const components = {
  ForceNewPassword: {
    FormFields() {
      return (
        <>
          <PasswordDescription />
          <Authenticator.ForceNewPassword.FormFields />
        </>
      );
    },
  },
  ConfirmResetPassword: {
    Header() {
      return (
        <>
          <Heading className="mb-8">パスワードをリセット</Heading>
          <PasswordDescription />
        </>
      );
    },
  },
  SetupTOTP: {
    Header() {
      return (
        <>
          <Heading className="mb-8">2要素認証アプリの設定</Heading>
          <Text className="text-center text-sm">
            2要素認証アプリを起動し、QRコードをカメラで読み取るか
            <br />
            表示されている認証コードをアプリ内で手動入力してください
          </Text>
        </>
      );
    },
  },
  ConfirmSignIn: {
    Header() {
      return (
        <>
          <Heading className="mb-8">2要素認証</Heading>
          <Text className="text-center text-sm">
            端末の認証アプリによりご登録いただいた
            <br />
            認証コードを入力してください
          </Text>
        </>
      );
    },
  },
};

export const Login = () => {
  return (
    <>
      <div className="h-10" />
      <Image
        src="/images/anygift-logo-landscape.png"
        alt="logo"
        width={202}
        height={200}
        className="my-8"
      />
      <Authenticator
        components={components}
        hideSignUp={true}
        formFields={AuthenticatorFormFields}
      />
    </>
  );
};
