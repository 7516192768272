export const AuthenticatorFormFields = {
  signIn: {
    username: {
      label: 'メールアドレス',
      labelHidden: false,
      placeholder: 'example@anygift.jp',
      isRequired: true,
    },
    password: {
      label: 'パスワード',
      labelHidden: false,
      placeholder: '●●●●●●',
      isRequired: true,
    },
  },
  resetPassword: {
    username: {
      label: 'メールアドレス',
      labelHidden: false,
      placeholder: 'メールアドレスを入力',
      isRequired: true,
    },
  },
  confirmResetPassword: {
    confirmation_code: {
      label: 'コード',
      labelHidden: false,
      placeholder: '確認コードを入力してください',
      isRequired: false,
    },
    password: {
      label: '新しいパスワード',
      labelHidden: false,
      placeholder: '●●●●●●',
      isRequired: false,
    },
    confirm_password: {
      label: 'パスワードを確認する',
      labelHidden: false,
      placeholder: '●●●●●●',
      isRequired: false,
    },
  },
  forceNewPassword: {
    password: {
      label: '新しいパスワード',
      labelHidden: false,
      placeholder: '●●●●●●',
      isRequired: false,
    },
    confirm_password: {
      label: '新しいパスワードを確認',
      labelHidden: false,
      placeholder: '●●●●●●',
      isRequired: false,
    },
  },
  setupTOTP: {
    confirmation_code: {
      label: '認証コード',
      labelHidden: false,
      placeholder: '123 456',
      isRequired: false,
    },
  },
  confirmSignIn: {
    confirmation_code: {
      label: '認証コード',
      labelHidden: false,
      placeholder: '123 456',
      isRequired: false,
    },
  },
};
