import NextHead from 'next/head';

import appleTouchIcon from './images/apple-touch-icon.png';
import favicon16x from './images/favicon-16x16.png';
import favicon32x from './images/favicon-32x32.png';

type Props = {
  title: string;
};

export const Head: React.FC<Props> = ({ title }) => (
  <NextHead>
    <meta charSet="UTF-8" />
    <meta name="viewport" content="initial-scale=1.0, width=device-width" />

    <title children={title} />

    {/* favicon */}
    <link rel="apple-touch-icon" sizes="180x180" href={appleTouchIcon.src} />
    <link rel="icon" type="image/png" sizes="16x16" href={favicon16x.src} />
    <link rel="icon" type="image/png" sizes="32x32" href={favicon32x.src} />
  </NextHead>
);
