import { Head } from '@/components/Head';
import { NonRequireAuth } from '@/components/NonRequireAuth';
import { Login } from '@/components/login/Login';

export default function LoginPage() {
  return (
    <>
      <Head title="ログイン" />
      <NonRequireAuth>
        <Login />
      </NonRequireAuth>
    </>
  );
}
